import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "./home.scss";
import video from "../../assets/background-video.mp4";
import biobot from "../../assets/card-biobot.png";
import training from "../../assets/card-trainingbot.png";
import analytics from "../../assets/card-analytics.png";
import accreditation from "../../assets/card-accradation.png";
import accreditation1 from "../../assets/accreditation1.png";
import survey from "../../assets/card-surveybot.png";
import draft from "../../assets/card-draftbot.png";
import trainingAsmt from "../../assets/card-training-asmt.png";
import build from "../../assets/buildbot.svg";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import lock from "../../assets/lock.svg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Mousewheel, Navigation } from "swiper/modules";

const Home = (props) => {
  const navigate = useNavigate();

  const handleClick = (path, idx) => {
    console.log("idx", idx);
    navigate(`/${path}`);
    props.setActiveCard(idx);
  };

  return (
    <div className="home-screen">
      <div className="video-wrapper">
        <video
          preload="metadata"
          playsInline
          autoPlay
          muted
          loop
          // id="landing-video"
          // poster="../../assets/homebackground.png"
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="header">
          <div className="heading">
            <Typography variant="h4" className="">
              {/* Welcome to a world of GenAI powered lifesciences solutions. */}
            </Typography>
            {/* <Typography variant="h6" style={{ marginBottom: "5%" }}>
          Explore a wealth of global healthcare information, effortlessly
          generate regulatory documents, and conduct in-depth research on life
          <br />
          sciences topics within our suite of sub-applications.
        </Typography> */}
          </div>
          <div className="section2">
            {/* <div className="card-biobot">
          <Grid onClick={() => handleClick("fdaDraft")}>
            <Card sx={{ maxWidth: 345 }}>
              <CardContent className="card-content">
                <img src={draft} className="images"></img>
                <Typography variant="h5" className="card-content">
                 Notice Response
                </Typography>
                <hr className="hr-tag"></hr>
                <Typography className="card-content">
                 
                  Generate response to FDA notice using GenAI.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </div> */}
            <Swiper
              effect={"coverflow"}
              direction={"horizontal"}
              slidesPerView={3.5}
              spaceBetween={10}
              centeredSlides={true}
              loop={true}
              mousewheel={true}
              pagination={{
                clickable: true,
              }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 200,
                modifier: 1,
                slideShadows: false,
              }}
              navigation={true}
              modules={[EffectCoverflow, Mousewheel, Navigation]}
              className="mySwiper"
              initialSlide={props.activeCard}
            >
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("bioBot", 0)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      <img src={biobot} alt="" className="images"></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        {/* Bio Bot */}Q & A
                      </Typography>
                      <Typography className="card-content-p">
                        Multimodal conversational bot with up-to-date,
                        personalized, and customized information.
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("qaAnalysis", 1)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      <img src={analytics} alt="" className="images"></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        Analytics Dashboard
                      </Typography>
                      <Typography className="card-content-p">
                        Insightful Data Analytics for Strategic Sales Solutions
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("docGenerate", 2)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      {/* <img
                src={lock}
                className="images"
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "8px",
                  height: "50px",
                  width: "50px",
                }}
              /> */}
                      <img src={draft} alt="" className="images"></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        Draft Bot
                      </Typography>
                      <Typography className="card-content-p">
                        Revolutionary GenAI powered document drafting.
                      </Typography>
                    </CardContent>
                  </Card>
                </div>{" "}
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("training", 3)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      <img src={training} alt="" className="images"></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        Training Bot
                      </Typography>
                      <Typography className="card-content-p">
                        AI-Driven, Customized learning and development tool
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("assessment", 4)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      <img src={trainingAsmt} alt="" className="images"></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        Training Assessment
                      </Typography>
                      <Typography className="card-content-p">
                        GenAI Powered Training Assessment Dashboard
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("survey", 5)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      {/* <img
                src={lock}
                className="images"
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "8px",
                  height: "50px",
                  width: "50px",
                }}
              /> */}
                      <img src={survey} alt="" className="images"></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        Survey Bot
                      </Typography>
                      <Typography className="card-content-p">
                        Advanced AI-Powered Survey Automation
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("accreditation", 6)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      <img
                        src={accreditation1}
                        /* style={{height:"48%"}} */ alt=""
                        className="images"
                      ></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        {/* Accreditation */} ADA
                      </Typography>
                      <Typography className="card-content-p">
                        Automated Document Analyzer for reviewing and processing
                        of data
                        {/* Advanced AI-Powered Survey Automation{" "} */}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("fdaDraft", 6)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      <img
                        src={draft}
                        /* style={{height:"48%"}} */ alt=""
                        className="images"
                      ></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        Notice Response
                      </Typography>
                      <Typography className="card-content-p">
                        Generate response to FDA notice using GenAI.
                        {/* Advanced AI-Powered Survey Automation{" "} */}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <div
                  className="card-biobot"
                  onClick={() => handleClick("newBot", 0)}
                >
                  <Card sx={{ maxWidth: 400 }} className="home-card">
                    <CardContent className="card-content">
                      <img src={biobot} alt="" className="images"></img>{" "}
                      <Typography variant="h5" className="card-content-h5">
                        {/* Bio Bot */}Marathi Bot
                      </Typography>
                      <Typography className="card-content-p">
                        Multimodal conversational bot with up-to-date,
                        personalized, and customized information.
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </SwiperSlide>
            </Swiper>

            {/* <div className="card-biobot" onClick={() => handleClick("allBots")}>
          <Grid>
            <Card sx={{ maxWidth: 345 }}>
              <CardContent className="card-content">
                <img
                  src={lock}
                  className="images"
                  style={{ position: "absolute", right: "48px" }}
                />
                <img src={build} className="images"></img>
                <Typography variant="h5" className="card-content">
                  Build a Chatbot
                </Typography>
                <hr className="hr-tag"></hr>
                <Typography className="card-content">
                  Effortlessly create custom chatbots, with our cutting-edge,
                  no-code solution.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </div> */}
          </div>
          {/* <div className="section2"></div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
